<template>
  <div>
    <br />
    <div class="row">
      <div class="col-6">
        <label for="">Désignation</label>
        <input type="text" v-model="coldroom.fullName" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Type</label>
        <select v-model="coldroom.type" class="form-select">
          <option value="Chambre Froide Positive">Positive</option>
          <option value="Chambre Froide Négative">Négative</option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Numéro de chambre</label>
        <input type="text" v-model="coldroom.number" class="form-control" />
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(coldroom)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      coldroom: {},
    };
  },
  computed: {},
  methods: {
    async save(data) {
      await this.$store.dispatch("coldroom/store", data);
    },
    async empty() {
      this.$store.commit("coldroom/setColdroom", {});
    },
  },
  beforeMount() {},
};
</script>
